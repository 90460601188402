//import React from 'react';
//import { Box, Typography, Grid } from '@mui/material';
//
//const About = () => (
//  <Box sx={{ py: 10, px: 5, backgroundColor: '#F7F9FB', color: '#212A31' }}>
//    <Grid container spacing={4} alignItems="center">
//      <Grid item xs={12} md={6}>
//        <Typography variant="h4" sx={{ fontWeight: 'bold', mb: 3 }}>
//          About Me
//        </Typography>
//        <Typography variant="body1">
//          I'm Marharyta, a professional with 6+ years of experience in
//          international management. I uncover strategies that drive growth across
//          cultures and industries. Let’s explore leadership, innovation, and
//          global markets together.
//        </Typography>
//      </Grid>
//      <Grid item xs={12} md={6}>
//        <Box
//          component="img"
//          src="/profile.jpg"
//          alt="Marharyta"
//          sx={{
//            width: '100%',
//            maxWidth: '350px',
//            borderRadius: '16px',
//            display: 'block',
//            mx: 'auto',
//            boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
//          }}
//        />
//      </Grid>
//    </Grid>
//  </Box>
//);
//
//export default About;

import React from 'react';
import { Box, Typography, Avatar } from '@mui/material';

const About = () => (
  <Box
   id="about"
    sx={{
      backgroundColor: '#828b88',
      padding: '40px 20px',
      textAlign: 'center',
      color: '#FFF',
    }}
  >

    <Avatar
      alt="Marharyta"
      src="/profile.jpeg"
      sx={{
        width: 150,
        height: 150,
        margin: '0 auto',
        border: '4px solid #1f5f25',
        boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.2)',
      }}
    />
    <Typography variant="h4" sx={{ color: '#1f4222', mt: 3, mb: 2, fontWeight: 'bold', fontSize: '2rem', }}>
      About Me
    </Typography>
    <Typography variant="body1" sx={{ color: '#1f4222', fontSize: '1.1rem', lineHeight: 1.6, maxWidth: '800px', margin: '0 auto' }}>
      I’m Marharyta, an international business strategist with 6+ years of experience in global market expansion, cross-border growth, and digital transformation.
      I help Small and Medium Enterprises navigate new markets, scale sustainably, and build strategies that work across cultures and industries.
    </Typography>
  </Box>
);

export default About;
