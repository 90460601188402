import React, { useState } from 'react';
import { Box, Typography, Button, Stack, Modal, Fade, Backdrop } from '@mui/material';
import LeadForm from './LeadForm'; // Import the lead generation form

const Hero = () => {
  const [openModal, setOpenModal] = useState(false);

  const handleScrollToAbout = () => {
    const aboutSection = document.getElementById('about');
    if (aboutSection) {
      aboutSection.scrollIntoView({ behavior: 'smooth' });
    } else {
      console.error('The About section with id="about" was not found in the DOM.');
    }
  };

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  return (
    <Box
      sx={{
        height: '96vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
        background: 'url(/back3.jpeg) center/cover no-repeat',
        color: '#FFF',
        textAlign: 'center',
      }}
    >
      {/* Overlay for readability */}
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(0, 0, 0, 0.4)', // Semi-transparent black overlay
          zIndex: 1,
        }}
      ></Box>

      {/* Content */}
      <Box
        sx={{
          position: 'relative',
          zIndex: 2,
          padding: '0 20px',
        }}
      >
        <Typography variant="h2" sx={{ fontWeight: 'bold', mb: 2 }} textAlign="left">
          Expand Without Limits
        </Typography>
        <Typography variant="h6" sx={{ mb: 4 }} textAlign="left">
          Accelerate your business growth with tailored strategies for seamless global market entry
        </Typography>
        <Stack direction="row" spacing={2}>
          <Button
            variant="contained"
            onClick={handleScrollToAbout}
            sx={{
              backgroundColor: '#6FAE75',
              padding: '10px 20px',
              '&:hover': { backgroundColor: '#558D61' },
            }}
          >
            <b>Learn More</b>
          </Button>
          <Button
            variant="outlined"
            onClick={handleOpenModal}
            sx={{
              color: '#FFF',
              borderColor: '#6FAE75',
              padding: '10px 20px',
              '&:hover': {
                borderColor: '#558D61',
                color: '#6FAE75',
              },
            }}
          >
            <b>Get Your Free Market Analysis</b>
          </Button>
        </Stack>
      </Box>

      {/* Modal for Lead Form */}
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openModal}>
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: 400,
              bgcolor: 'background.paper',
              borderRadius: '8px',
              boxShadow: 24,
              p: 4,
            }}
          >
            <LeadForm />
          </Box>
        </Fade>
      </Modal>
    </Box>
  );
};

export default Hero;
