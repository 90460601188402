import React, { useState } from 'react';
import { Box, TextField, Button, Typography } from '@mui/material';

const LeadForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    company: '',
    industry: '',
    target: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Here, you would normally send form data to the server or API
    console.log('Form submitted:', formData);
    alert('Thank you! We will get in touch soon.');
    // Reset form fields
    setFormData({ name: '', email: '', company: '', industry: '', target: '' });
  };

  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
      }}
    >
      <Typography variant="h5" textAlign="center" sx={{ mb: 2 }}>
        Get Your Free Market Analysis
      </Typography>
      <TextField
        label="Name"
        name="name"
        value={formData.name}
        onChange={handleChange}
        fullWidth
        required
      />
      <TextField
        label="Email"
        name="email"
        value={formData.email}
        onChange={handleChange}
        fullWidth
        type="email"
        required
      />
      <TextField
        label="Company"
        name="company"
        value={formData.company}
        onChange={handleChange}
        fullWidth
      />
      <TextField
        label="Industry"
        name="industry"
        value={formData.industry}
        onChange={handleChange}
        fullWidth
        required
      />
      <TextField
        label="Target market"
        name="target"
        value={formData.target}
        onChange={handleChange}
        fullWidth
        required
      />

      <Button
        type="submit"
        variant="contained"
        sx={{
          backgroundColor: '#6FAE75',
          '&:hover': { backgroundColor: '#558D61' },
        }}
      >
        Get My Free Analysis
      </Button>
    </Box>
  );
};

export default LeadForm;
