import React from 'react';
import { CssBaseline } from '@mui/material';
import Hero from './components/Hero';
import About from './components/About';
import Topics from './components/Topics';
import Contact from './components/Contact';
import Highlight from './components/Highlight';

const App = () => {
  return (
    <>
      <CssBaseline />
      <Hero />
      <About />
      <Topics />
      <Highlight />
      <Contact />
    </>
  );
};
export default App;
