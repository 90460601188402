import React from 'react';
import { Box, Grid, Card, CardContent, Typography } from '@mui/material';

const focusAreas = [
  {
    title: 'Market Entry Strategy',
    description: 'Detailed analysis and planning for entering new markets.'
  },
  {
    title: 'Regulatory Compliance',
    description: 'Guidance on legal and compliance requirements in target countries.'
  },
  {
    title: 'Cultural Localization',
    description: 'Adapting products and marketing strategies to local cultures.'
  },
  {
    title: 'Logistics and Supply Chain Management',
    description: 'Streamlining operations for international distribution.'
  },
];

const Topics = () => (
  <Box
    sx={{
      position: 'relative',
      padding: '60px 20px',
      background: 'url(/back3.jpeg) center/cover no-repeat',
    }}
  >
    {/* Overlay for opacity */}
    <Box
      sx={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.4)', // Semi-transparent overlay
        zIndex: 1,
      }}
    ></Box>

    {/* Content */}
    <Box
      sx={{
        position: 'relative',
        zIndex: 2,
      }}
    >
      <Typography
        variant="h4"
        sx={{
          textAlign: 'center',
          color: '#FFF',
          mb: 4,
          fontSize: '2rem',
        }}
      >
        Focus Areas
      </Typography>
      <Grid container spacing={4} justifyContent="center">
        {focusAreas.map((area, index) => (
          <Grid item xs={12} sm={6} md={6} key={index}> {/* Two columns on md and larger */}
            <Card
              sx={{
                backgroundColor: 'rgba(255, 255, 255, 0.7)', // Semi-transparent white
                boxShadow: 4,
                transition: 'transform 0.3s, box-shadow 0.3s',
                '&:hover': {
                  transform: 'scale(1.05)',
                  boxShadow: 7,
                },
              }}
            >
              <CardContent
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  padding: '30px 20px',
                }}
              >
                <Typography
                  variant="h6"
                  sx={{
                    color: '#1f5f25',
                    fontWeight: 'bold',
                    mb: 1,
                    textAlign: 'center',
                  }}
                >
                  {area.title}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    color: '#333',
                    textAlign: 'center',
                  }}
                >
                  {area.description}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  </Box>
);

export default Topics;
